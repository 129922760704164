<template>
  <span>
    <b-row>
      <b-col>
        <DxDataGrid
          key-expr="id"
          :data-source="dataSource"
          :focused-row-enabled="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          @init-new-row="handleEvent('init-new-row', $event)"
          @option-changed="handleEvent('option-changed', $event)"
          @row-removed="handleEvent('row-removed', $event)"
        >
          <DxEditing
            :allow-updating="!isDisabled"
            :allow-deleting="!isDisabled"
            :allow-adding="!isDisabled"
            :use-icons="!isDisabled"
            mode="row"
          />

          <DxColumn v-if="!isDisabled" type="buttons">
            <DxButton icon="edit" :onClick="editItem" />
            <DxButton name="delete" />
          </DxColumn>

          <DxColumnChooser :enabled="true" />
          <DxColumnFixing :enabled="true" />
          <DxSorting mode="multiple" />
          <DxFilterRow :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="false" />
          <DxHeaderFilter :visible="true" />
          <DxSearchPanel location="before" :visible="true" />

          <DxColumn data-field="product_id" caption="Product">
            <DxLookup
              :data-source="products"
              value-expr="id"
              display-expr="name"
            />
          </DxColumn>
          <DxColumn data-field="quantity" caption="Quantity" />

          <DxToolbar>
            <DxItem location="before" name="columnChooserButton" />
            <DxItem location="before" name="searchPanel" />
            <DxItem name="groupPanel" />

            <DxItem v-if="!isDisabled" template="addButtonTemplate" />
          </DxToolbar>

          <template #addButtonTemplate>
            <div>
              <b-button
                variant="primary"
                class="text-capitalize"
                @click="addModal = true"
              >
                Add
              </b-button>
            </div>
          </template>
        </DxDataGrid>
      </b-col>
    </b-row>

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Job Spare"
      @hide="closeModal()"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <h2>Job Spare</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="9">
          <b-form-group label="Product" label-for="v-product_id">
            <v-select
              v-model.number="formDetail.product_id"
              id="v-product_id"
              :options="products"
              label="name"
              :reduce="(v) => +v.id"
              @option:selected="onSelectedProduct($event)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="Quantity" label-for="v-quantity">
            <b-form-input
              id="v-quantity"
              class="text-right"
              v-model.number="formDetail.quantity"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Descriptions" label-for="v-description">
            <div class="" v-html="content"></div>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import jobSparesApi from '@api/job_spares';
import productsApi from '@api/products';
import { queryParameters } from '@/schema';

export default {
  name: 'JobSparesComponent',
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dataSource(v) {
      //
    },
  },
  data: () => ({
    dataIndex: -1,
    addModal: false,
    content: null,
    products: [],
    formDetail: {
      id: 0,
      product_id: 0,
      quantity: 0,
    },
  }),
  mounted() {
    this.loadProducts();
  },
  methods: {
    loadProducts() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      productsApi
        .list(params)
        .then(({ data }) => {
          this.products = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        product_id: 0,
        quantity: 0,
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.content = null;
        this.formDetail = Object.assign({}, defaultForm);
      });
    },
    handleEvent(action, event) {
      if (action === 'row-removed') {
        jobSparesApi.delete(event.key);
      }
    },
    editItem(event) {
      if (event) {
        this.addModal = true;
        this.isSavingDisabled = false;
        this.$nextTick(() => {
          const data = event.row.data;

          this.dataIndex = event.row.dataIndex;
          this.formDetail = Object.assign({}, data);
        });
      }
    },
    previewItem(event) {
      //
    },
    onSubmit() {
      if (this.dataIndex > -1) {
        this.dataSource.splice(this.dataIndex, 1);
        this.dataSource.splice(this.dataIndex, 0, this.formDetail);
      } else {
        this.dataSource.push(this.formDetail);
      }

      this.closeModal();
    },
    onSelectedProduct(item) {
      this.content = item.description;
    },
  },
};
</script>

<style lang="scss"></style>
