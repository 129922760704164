import api from '@apiInstance';

export default {
  add (data) {
    return api.post('jobs', data);
  },
  get (id) {
    return api.fetch('jobs/' + id);
  },
  list (params) {
    return api.fetch('/jobs', { params });
  },
  update (data) {
    return api.patch('jobs', data);
  },
  delete (id) {
    return api.remove('jobs', id);
  }
};
