import api from '@apiInstance';

export default {
  add (data) {
    return api.post('checklist_template_headers', data);
  },
  get (id) {
    return api.fetch('checklist_template_headers/' + id);
  },
  list (params) {
    return api.fetch('/checklist_template_headers', { params });
  },
  update (data) {
    return api.patch('checklist_template_headers', data);
  },
  delete (id) {
    return api.remove('checklist_template_headers', id);
  },

  createChecklist (body) {
    return api.request({
      url: 'checklist_template_headers/',
      method: 'POST',
      body,
      type: 'checklist_template_headers'
    });
  }
};
