<template>
  <span>
    <b-row>
      <b-col>
        <DxDataGrid
          key-expr="id"
          :data-source="dataSource"
          :focused-row-enabled="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          @init-new-row="handleEvent('init-new-row', $event)"
          @option-changed="handleEvent('option-changed', $event)"
          @row-removed="handleEvent('row-removed', $event)"
        >
          <DxEditing
            :allow-updating="!isDisabled"
            :allow-deleting="!isDisabled"
            :allow-adding="!isDisabled"
            :use-icons="!isDisabled"
            mode="row"
          />

          <DxColumn v-if="!isDisabled" type="buttons">
            <DxButton icon="edit" :onClick="editItem" />
            <DxButton name="delete" />
          </DxColumn>

          <DxColumnChooser :enabled="true" />
          <DxColumnFixing :enabled="true" />
          <DxSorting mode="multiple" />
          <DxFilterRow :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="false" />
          <DxHeaderFilter :visible="true" />
          <DxSearchPanel location="before" :visible="true" />

          <DxColumn data-field="description" caption="Description" />
          <DxColumn data-field="file" caption="Attachment" />

          <DxToolbar>
            <DxItem location="before" name="columnChooserButton" />
            <DxItem location="before" name="searchPanel" />
            <DxItem name="groupPanel" />

            <DxItem v-if="!isDisabled" template="addButtonTemplate" />
          </DxToolbar>

          <template #addButtonTemplate>
            <div>
              <b-button
                variant="primary"
                class="text-capitalize"
                @click="addModal = true"
              >
                Add
              </b-button>
            </div>
          </template>
        </DxDataGrid>
      </b-col>
    </b-row>

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Job Instruction"
      @hide="closeModal()"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <h2>Job Instruction</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Upload File" label-for="v-file-upload">
            <b-form-file
              v-model="onFileUpload"
              placeholder="Choose/Drop a file here..."
              drop-placeholder="Drop a file here..."
              id="v-file-upload"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="formDetail.base64 || formDetail.file">
        <b-col class="text-center">
          <b-form-group>
            <b-img
              v-if="getFileType !== 'pdf'"
              style="margin-bottom: 5px"
              height="250"
              width="250"
              thumbnail
              fluid
              :src="getImageSource"
            />

            <iframe
              v-if="getFileType === 'pdf'"
              style="margin-bottom: 5px"
              height="250"
              width="250"
              thumbnail
              fluid
              :src="getImageSource"
            />

            <br />
            <b-button
              @click="onFileRemove"
              type="button"
              variant="outline-danger"
              size="sm"
            >
              Remove
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group>
            <quill-editor ref="quillEditorRef" v-model="content" />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import jobInstructionsApi from '@api/job_instructions';

export default {
  name: 'JobInstructionsComponent',
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    onFileUpload(file) {
      if (file) {
        console.log(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.formDetail.file = file.name;
          this.formDetail.base64 = reader.result;

          this.fileExtension = file.type;
        };
      }
    },
  },
  computed: {
    getImageSource: {
      get() {
        if (+this.$route.query.id) {
          if (this.formDetail.base64) {
            return this.formDetail.base64;
          }

          if (!this.formDetail.base64 && this.formDetail.file) {
            return this.formDetail.file;
          }
        }

        return this.formDetail.base64;
      },
    },
    getFileType: {
      get() {
        if (this.fileExtension && this.fileExtension.includes('/')) {
          return this.fileExtension.split('/')[1];
        }
      },
    },
  },
  data: () => ({
    dataIndex: -1,
    addModal: false,
    content: null,
    onFileUpload: null,
    fileExtension: null,
    formDetail: {
      id: 0,
      file: null,
      description: null,

      base64: null,
    },
  }),
  mounted() {
    //
  },
  methods: {
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        file: null,
        description: null,

        base64: null,
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.content = null;
        this.formDetail = Object.assign({}, defaultForm);
      });
    },
    handleEvent(action, event) {
      if (action === 'row-removed') {
        // jobInstructionsApi.delete(event.key);
      }
    },
    editItem(event) {
      if (event) {
        this.addModal = true;
        this.isSavingDisabled = false;
        this.$nextTick(() => {
          const data = event.row.data;

          this.dataIndex = event.row.dataIndex;
          this.formDetail = Object.assign({}, data);
        });
      }
    },
    previewItem(event) {
      //
    },
    onSubmit() {
      this.formDetail.description = this.content;
      if (this.dataIndex > -1) {
        this.dataSource.splice(this.dataIndex, 1);
        this.dataSource.splice(this.dataIndex, 0, this.formDetail);
      } else {
        this.dataSource.push(this.formDetail);
      }

      this.closeModal();
    },
    onFileRemove() {
      this.onFileUpload = null;
      this.formDetail.file = null;
      this.formDetail.base64 = null;
    },
  },
};
</script>

<style lang="scss"></style>
