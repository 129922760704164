import api from '@apiInstance';

export default {
  add(data) {
    return api.post('jobs_spares', data);
  },
  get(id) {
    return api.fetch('jobs_spares/' + id);
  },
  list(params) {
    return api.fetch('/jobs_spares', { params });
  },
  update(data) {
    return api.patch('jobs_spares', data);
  },
  delete(id) {
    return api.remove('jobs_spares', id);
  },
};
